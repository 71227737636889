@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --primaryColour: #3e16f2;
    --secondaryColour: #3e16f2ac;
    --pinkColor : #ff6781
}

body {
    font-family: 'Bebas Neue', cursive;

    
}

/* ------------- Custom scrollbar styling ---------------- */
::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #fad96100, var(--primaryColour));
    border-radius: 16px;
}

::-webkit-scrollbar-track {
    background: #272846;
    border-radius: 16px;
}

/* ------------- Custom scrollbar styling ---------------- */



/* ----------- Toggle switch customization ------------- */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 22px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* ----------- Toggle switch customization ------------- */



/* ---------- Buttons styling ------------------ */
.btn {
    background: #131313;
    box-shadow: 0 4px 10pxrgba(0, 0, 0, .5);
    color: #fff;
    border-radius: 7px;
    box-shadow: 0 0 5px var(--primaryColour);
    transition: all .5s ease;
    cursor: pointer;
    font-size: 25px;
    font-weight: 400;
    height: 48px;
    padding: 0 24px;
}

.btn:hover {
    background-color: #1a1f2e;
    box-shadow: 0 4px 10px rgb(0 0 0 / 50%);
    border-radius: 7px;
    box-shadow: 0 0 20px var(--primaryColour);
    transition: all .5s ease;
    color: #fff;
}

.claim-btn {
    background: var(--primaryColour);
    box-sizing: border-box;
    color: #fff;
    box-shadow: 0 0 0 var(--secondaryColour);
    transition: all .5s ease;
    font-size: 25px;
    padding: 0 24px;
    cursor: pointer;
    font-weight: 400;
    height: 48px;
    border-radius: 7px
}

.claim-btn:hover {
    box-shadow: 0 0 20px var(--secondaryColour);
}

.wallet-info {
    border-radius: 20px;
    background-position: 0;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-right: 5px solid var(--primaryColour);
    border-left: 5px solid var(--primaryColour);
    font-size: 25px;
    font-family: 'Bebas Neue', cursive;
    font-weight: 400;
    line-height: 24px;
    box-shadow: 0 0 20px var(--primaryColour);
    border-radius: 20px;
    background: rgba(0, 0, 0, .7);
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 10px;
}

.wallet-btns-container {
    background: rgba(0, 0, 0, .7);
    border-radius: 20px;
    border-bottom: 6px solid var(--primaryColour);
    box-shadow: 0 0 20px var(--primaryColour);
    backdrop-filter: blur(3px);
}

.wallet-btns-container-top {
    padding: 10px 0 0 15px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 5px;
    margin-right: 0;
    height: 420px;
    margin-bottom: 20px;
}

.wallet-btns-container-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px 10px;
    border-radius: 30px;
    flex-direction: column;
}

button:disabled {
    opacity: 0.3;
}

/* ---------- Buttons styling ------------------ */


.image {
    position: relative;
    border-radius: 1.3rem;
    border: 5px solid var(--secondaryColour);
}

.image>img {
    cursor: pointer;
    border-radius: 1rem;
}

.image-caption {
    position: absolute;
    bottom: 1%;
    color: white;
    left: 5%;
    z-index: 20;
    font-size: 1rem;
}

.image::after {
    box-shadow: inset 0 -30px 10px 0 #000000be;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 1rem;
    cursor: pointer;
}

.image:active {
    border: 5px solid var(--primaryColour);
}


.image-active {
    border: 5px solid var(--pinkColor);
}

.image>img:nth-of-type(odd) {
    margin-right: 3%;
}

@media (min-width:640px) {
    .image-caption {
        font-size: 1.2rem;
    }
}